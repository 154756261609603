@import '../bootstrap_overrides/_variables.scss';
@import '../mixins/_custom-mixins.scss';

//TEXT CAROUSEL
.pb-text-carousel {
    .image-wrapper {
        width: 100%;
        margin-bottom: 2em;
        // padding-top: 70%;
        // NEW
        height: 25rem;
        overflow: hidden;
        display: none;
        img {
            object-fit: cover;
            height: 100%;
            width: 100%;
        }

        &.offset-image {
            // padding-top: 120%;
            // NEW
            height: 42rem;
        }
    }
    h4.post-title {
        font-size: 25px;
        min-height: 65px;
    }
    .icon-wrapper {
        border: 3px solid $black;
        width: 45%;
        margin-bottom: 2em;
        border-radius: 100%;
        overflow: hidden;
    }
    .bg-black, .bg-red {
        .icon-wrapper {
            border-color: $white;
        }
    }
    .columns-three {
        .column-2 {
            margin: 0 auto;
        }
        .column-3 {
            float: right;

            @include bpr-media-breakpoint(down, md) {
                float: none;
            }
        }
    }
    &.offset-carousel {
        padding-bottom: 460px;
        .bg-white {
            padding-bottom: 6em;
        }
        .bg-black {
            .btn,
            button {
                &.btn-outline-red {
                    color: $red;
                    &:hover {
                        color: $white;
                    }
                }
            }
        }
        .text-carousel-three {
            margin-bottom: -550px;
        }
    }

    @include bpr-media-breakpoint(down, md) {
        .text-carousel-three-standard {
            margin-bottom: 4em;
            .slick-next {
                top: auto !important;
                right: auto;
                left: calc(50% - 24px);
                bottom: -8rem;
                transform: rotate(90deg);
            }
        }

        .item-inner {
            padding-right: 0;
            padding-top: 2rem;
            padding-bottom: 4rem;
        }

        .icon-wrapper {
            margin-left: auto;
            margin-right: auto;
        }
    }
}