:root {
  --primary: #CA5F33;
}

.border-top-red {
  border-top-color: #E41B13 !important;
}

.border-red {
  border-color: #E41B13;
}

.border-2 {
  border-width: 2px !important;
}

.pb-text-carousel .image-wrapper {
  width: 100%;
  margin-bottom: 2em;
  height: 25rem;
  overflow: hidden;
  display: none;
}
.pb-text-carousel .image-wrapper img {
  object-fit: cover;
  height: 100%;
  width: 100%;
}
.pb-text-carousel .image-wrapper.offset-image {
  height: 42rem;
}
.pb-text-carousel h4.post-title {
  font-size: 25px;
  min-height: 65px;
}
.pb-text-carousel .icon-wrapper {
  border: 3px solid #000;
  width: 45%;
  margin-bottom: 2em;
  border-radius: 100%;
  overflow: hidden;
}
.pb-text-carousel .bg-black .icon-wrapper, .pb-text-carousel .bg-red .icon-wrapper {
  border-color: #fff;
}
.pb-text-carousel .columns-three .column-2 {
  margin: 0 auto;
}
.pb-text-carousel .columns-three .column-3 {
  float: right;
}
@media (max-width: 960px) {
  .pb-text-carousel .columns-three .column-3 {
    float: none;
  }
}
.pb-text-carousel.offset-carousel {
  padding-bottom: 460px;
}
.pb-text-carousel.offset-carousel .bg-white {
  padding-bottom: 6em;
}
.pb-text-carousel.offset-carousel .bg-black .btn.btn-outline-red,
.pb-text-carousel.offset-carousel .bg-black button.btn-outline-red {
  color: #E41B13;
}
.pb-text-carousel.offset-carousel .bg-black .btn.btn-outline-red:hover,
.pb-text-carousel.offset-carousel .bg-black button.btn-outline-red:hover {
  color: #fff;
}
.pb-text-carousel.offset-carousel .text-carousel-three {
  margin-bottom: -550px;
}
@media (max-width: 960px) {
  .pb-text-carousel .text-carousel-three-standard {
    margin-bottom: 4em;
  }
  .pb-text-carousel .text-carousel-three-standard .slick-next {
    top: auto !important;
    right: auto;
    left: calc(50% - 24px);
    bottom: -8rem;
    transform: rotate(90deg);
  }
  .pb-text-carousel .item-inner {
    padding-right: 0;
    padding-top: 2rem;
    padding-bottom: 4rem;
  }
  .pb-text-carousel .icon-wrapper {
    margin-left: auto;
    margin-right: auto;
  }
}