$font-family: 'raleway', sans-serif;
$font-family-italic: 'raleway-italic', sans-serif;
$heading: 'raleway', sans-serif;
$heading-italic: 'raleway-italic', sans-serif;

$bg-white: #fff;
$black: #000;
$white: #fff;
$red: #E41B13;
$primary: #E41B13;

:root {
    --primary: #CA5F33;
}

// BOOTSTRAP GRID
$grid-breakpoints: (
    xs: 0,
    sm: 520px,
    md: 960px,
    lg: 1300px,
    xl: 1540px,
    xxl: 1820px
);

$container-max-widths: (
    sm: 620px,
    md: 940px,
    lg: 1250px,
    xl: 1480px,
    xxl: 1770px
);

.border-top-red {
    border-top-color: $red !important;
}
.border-red {
    border-color: $red;
}
.border-2 {
    border-width: 2px !important;
}